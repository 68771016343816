import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {Box, Image} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {EXPERIENCE} from 'src/routes/paths';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {homepage as copy} from 'src/utils/copy';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import styled from 'styled-components/macro';

const {H1, P1} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  position: ['relative', null, null, 'absolute'],
  top: [0, null, null, '50%'],
  left: [null, null, null, '5rem'],
  transform: [null, null, null, 'translateY(-50%)'],
})`
  .highlight {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
    margin-top: 1rem;
    display: block;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    span:first-child {
      display: block;
    }

    .highlight {
      margin-top: 0;
      margin-right: 0.75rem;
      display: inline-block;
    }
  }
`;

const StyledH1: typeof H1 = styled(H1).attrs({
  fontSize: ['4.25rem', null, null, null, '5rem'],
  lineHeight: ['4.25rem', null, null, null, '5rem'],
})``;

const StyledP1: typeof P1 = styled(P1).attrs({
  fontSize: ['1.3125rem', null, null, null, '1.5rem'],
  lineHeight: ['1.625rem', null, null, null, '1.8125rem'],
  maxWidth: '38.85rem',
  mt: ['1.5rem', null, null, '0.75rem'],
})``;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'neonGreen',
  width: ['100%', null, null, 'auto'],
  mt: ['1.5rem', null, null, '0.75rem'],
})``;

const StyledWebImage: typeof Image = styled(Image)`
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 3%, rgba(0, 0, 0, 0.5) 800px, rgba(0, 0, 0, 1) 100%);
`;

export const Hero: React.FC = ({...props}) => {
  return (
    <Box
      color="cream"
      position="relative"
      padding={['1.5rem', null, null, 0]}
      background={[null, null, null, '#040405']}
    >
      <TabletUp>
        <StyledWebImage src="https://assets.chief.com/lohp/images/homepage/hero.jpg" aria-label={copy.hero.ariaLabel} />
      </TabletUp>

      <StyledBox>
        <StyledH1>
          <span>{copy.hero.heading1}</span>

          <span className="highlight">{copy.hero.heading2}</span>

          <span>{copy.hero.heading3}</span>
        </StyledH1>

        <StyledP1>{copy.hero.description}</StyledP1>

        <StyledFireworkCTA
          onClick={(e: MouseEvent) => FireworkCTAClick(e, 'homepage.hero', EXPERIENCE)}
          to={EXPERIENCE}
        >
          {copy.hero.cta}
        </StyledFireworkCTA>
      </StyledBox>

      <MobileOnly>
        <Image
          mt="1.5rem"
          src="https://assets.chief.com/lohp/images/homepage/hero_mobile.png"
          aria-label={copy.hero.ariaLabel}
        />
      </MobileOnly>
    </Box>
  );
};
