import React from 'react';

import {Box} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import styled from 'styled-components/macro';

const {H1, P2} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  className: 'stat',
  width: ['100%', null, null, 'calc(33% - 1.5rem)'],
})`
  &:last-of-type .border {
    display: none;
  }
`;

const StyledH1: typeof H1 = styled(H1).attrs({
  fontSize: ['6.1875rem', '4.875rem', null, null, '6.875rem'],
  lineHeight: ['6.1875rem', '4.875rem', null, null, '6.875rem'],
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  fontSize: ['2rem', '1.5rem', null, null, '2rem'],
  lineHeight: ['2rem', '1.5rem', null, null, '2rem'],
  mx: 'auto',
})`
  font-family: ${fonts.primary};
`;

const Border: typeof Box = styled(Box).attrs({
  className: 'border',
  width: '1px',
  height: '4.125rem',
  display: ['none', null, null, null, 'block'],
  position: 'absolute',
  top: '50%',
  right: '-0.75rem',
  transform: 'translateY(-50%)',
})``;

type StatProps = {
  stat?: string;
  statColor?: string;
  description?: string;
  descriptionColor?: string;
};

export const Stat: React.FC<StatProps> = ({...props}) => {
  return (
    <StyledBox mt={['3rem', null, null, 0]} textAlign="center" position="relative">
      <StyledH1 color={props.statColor} fontStyle="italic">
        {props.stat}
      </StyledH1>

      <StyledP2 color={props.descriptionColor}>{props.description}</StyledP2>

      <Border backgroundColor={props.statColor} borderColor={props.statColor} />
    </StyledBox>
  );
};

Stat.defaultProps = {
  stat: '',
  statColor: 'neonGreen',
  description: '',
  descriptionColor: 'neonGreen',
};
