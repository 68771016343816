import React from 'react';

import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {NetworkingSection, NetworkingTopPositioning} from 'src/components/homepage/NetworkingSection';
import {ArticleItem} from 'src/components/homepage/shared/Article';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {EDITORIAL} from 'src/routes/paths';
import {homepage as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, CTA} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'cream',
  color: 'midnightBlue',
  pb: ['3rem', null, null, '4.5rem'],
})``;

const StyledBox: typeof Box = styled(Box).attrs({
  mt: NetworkingTopPositioning.map(x => `-${x / 2 - 4.5}rem`),
  mx: 'auto',
  px: '1.5rem',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledCTA: typeof CTA = styled(CTA).attrs({
  textAlign: ['left', null, null, 'right'],
  textTransform: 'uppercase',
  mt: ['0.75rem', null, null, '1.5rem'],
})`
  &:hover {
    text-decoration: underline;
  }
`;

const handleClickLink = (clickText: string, clickUrl: string) => {
  Events.didClickLink({clickText, clickUrl});
};

export const ArticlesSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <NetworkingSection containerMaxWidth={props.containerMaxWidth} />

      <StyledBox maxWidth={props.containerMaxWidth}>
        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between">
          <StyledH2>{copy.articles.heading}</StyledH2>

          <Anchor onClick={() => handleClickLink('homepage.articles.view-all', EDITORIAL)} to={EDITORIAL}>
            <StyledCTA>{copy.articles.cta}</StyledCTA>
          </Anchor>
        </Flex>

        <Box width="100%" height="1px" mt={['0.75rem', null, null, '1.5rem']} backgroundColor="midnightBlue" />

        <Flex mt="1.5rem" flexDirection={['column', null, null, 'row']} justifyContent="space-between">
          {copy.articles.articles.map((article, i) => (
            <ArticleItem key={i} {...article} />
          ))}
        </Flex>
      </StyledBox>
    </Wrapper>
  );
};
