import React, {useRef, useState} from 'react';

import {debounce} from 'lodash';
import {isSSR} from 'src/utils/ssr';

/**
 * Effect to calculate how many times we need to repeat the children to fill the container
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFillElementCount = ({
  minRepeatCount = 1,
  timesToFill = 4,
}: {
  minRepeatCount: number;
  timesToFill?: number;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const elementWidthRef = useRef<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [repeatCount, setRepeatCount] = useState(minRepeatCount);

  // Effect to calculate how many times we need to repeat the children to fill the container
  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (elementRef.current && wrapperRef.current) {
        if (elementWidthRef.current === null) {
          // Store initial width of icon grid
          elementWidthRef.current = elementRef.current.clientWidth;
        }
        const wrapperWidth = wrapperRef.current.clientWidth;

        // Set repeat count to make sure icons fit in at least timesToFill times of wrapper width
        let count = Math.ceil((wrapperWidth * timesToFill) / elementWidthRef.current);
        if (count % 2 !== 0) {
          // The repeat count must be even for the animation to be seamless
          count++;
        }
        setRepeatCount(count !== Infinity ? Math.max(count, minRepeatCount) : minRepeatCount);
      }
    }, 250);

    if (!isSSR) {
      handleResize();
      window?.addEventListener('resize', handleResize);
    }

    return () => {
      if (!isSSR) {
        window?.removeEventListener('resize', handleResize);
      }
    };
  }, [elementRef, wrapperRef]);

  return {elementRef, wrapperRef, repeatCount};
};
