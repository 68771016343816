import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {Aspect} from 'src/components/homepage/shared/Aspect';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {EXPERIENCE} from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {Icon, Icons} from 'src/svgs';
import {homepage as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, H3, P2} = Typography;

const StyledH2: typeof H2 = styled(H2).attrs({
  maxWidth: ['100%', null, null, '35.0625rem'],
  fontSize: ['2.875rem', null, null, '5rem'],
  lineHeight: ['3rem', null, null, '5rem'],
})``;

const StyledH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.3125rem', null, null, '1.75rem'],
  lineHeight: ['1.3125rem', null, null, '1.75rem'],
  mx: '0.75rem',
  textAlign: 'center',
})`
  text-wrap: nowrap;
`;

const StyledP2: typeof P2 = styled(P2).attrs({
  maxWidth: ['100%', null, null, '39.0625rem'],
  fontSize: '1.0625rem',
  mx: ['auto', null, null, 0],
  mt: '1.5rem',
})``;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  maxWidth: ['100%', null, null, '69.875rem'],
  mt: ['1.5rem', null, null, '3rem'],
  mx: 'auto',
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'space-between',
})`
  svg {
    margin: auto;
  }
`;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'midnightBlue',
  width: ['100%', null, null, 'auto'],
  mt: ['3rem', null, null, '4.5rem'],
})``;

export const InvestSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Box backgroundColor="lightGrey" color="midnightBlue" padding="1.5rem" pb="4.5rem">
      <Box maxWidth={props.containerMaxWidth} mx="auto">
        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between">
          <StyledH2>{copy.invest.heading}</StyledH2>

          <StyledP2>{copy.invest.description}</StyledP2>
        </Flex>

        <StyledFlex>
          {copy.invest.communityAspects.map((aspect, i) => (
            <Aspect key={i} {...aspect} />
          ))}
        </StyledFlex>

        <Flex mt="3rem" flexDirection="row" justifyContent="center">
          <Icon icon={Icons.homepageLeftSwoosh} aria-label="The Chief Community" />

          <StyledH3>{copy.invest.communityAspectsHeading}</StyledH3>

          <Icon icon={Icons.homepageRightSwoosh} aria-label="The Chief Community" />
        </Flex>

        <Box textAlign="center">
          <StyledFireworkCTA
            onClick={(e: MouseEvent) => FireworkCTAClick(e, 'homepage.invest', EXPERIENCE)}
            to={EXPERIENCE}
          >
            {copy.invest.cta}
          </StyledFireworkCTA>
        </Box>
      </Box>
    </Box>
  );
};
