import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {LogoSection} from 'src/components/homepage/ImpactSection/LogoSection';
import {NetworkingTopPositioning} from 'src/components/homepage/NetworkingSection';
import {Stat} from 'src/components/homepage/shared/Stat';
import {StatsCarousel} from 'src/components/homepage/shared/StatsCarousel';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {homepage as copy} from 'src/utils/copy';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import styled from 'styled-components/macro';

const {H2, H3} = Typography;

const StlyedH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StlyedH3: typeof H3 = styled(H3).attrs({
  color: 'cream',
  mt: '4.5rem',
  textAlign: ['center', null, null, 'left'],
  pl: [0, null, null, '4.5rem'],
  position: 'relative',
})`
  &:after {
    content: '';
    background-color: ${themeGet('colors.neonGreen')};
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
  }

  span {
    background-color: ${themeGet('colors.darkGreen')};
    padding: 0 1.5rem;
    display: inline-block;
    position: relative;
    z-index: 100;
  }
`;

const StlyedFlex: typeof Flex = styled(Flex).attrs({
  mt: ['1.5rem', null, null, '3rem'],
  pl: [0, null, null, '1.5rem', 0],
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'space-between',
})``;

export const ImpactSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Box
      backgroundColor="darkGreen"
      color="neonGreen"
      py="3rem"
      pb={NetworkingTopPositioning.map(x => `${x / 2 + 3}rem`)}
    >
      <Box px="1.5rem" maxWidth={props.containerMaxWidth} mx="auto" textAlign="center">
        <StlyedH2 color="neonGreen">{copy.impact.heading}</StlyedH2>

        <MobileOnly>
          <StatsCarousel stats={copy.impact.stats} statColor="neonGreen" descriptionColor="neonGreen" />
        </MobileOnly>

        <TabletUp>
          <StlyedFlex>
            {copy.impact.stats.map((stat, i) => (
              <Stat key={i} statColor="neonGreen" descriptionColor="neonGreen" {...stat} />
            ))}
          </StlyedFlex>
        </TabletUp>
      </Box>

      <StlyedH3>
        <span>{copy.impact.featuredHeading}</span>
      </StlyedH3>

      <LogoSection />
    </Box>
  );
};
