import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {LogoSection} from 'src/components/homepage/ClientsSection/LogoSection';
import {Stat} from 'src/components/homepage/shared/Stat';
import {StatsCarousel} from 'src/components/homepage/shared/StatsCarousel';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {homepage as copy} from 'src/utils/copy';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem', null, null],
  lineHeight: ['3rem', null, null, '3.125rem', null, null],
  mt: '1.5rem',
})`
  .highlight {
    color: ${themeGet('colors.periwinkle')};
    font-style: italic;
  }

  span:first-child,
  span:nth-child(4) {
    margin-right: 0.75rem;
  }

  span:nth-child(3) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    .highlight,
    span:nth-child(3) {
      margin-right: 0.75rem;
      display: inline;
    }
  }
`;

const StyledP2: typeof P2 = styled(P2).attrs({
  maxWidth: ['100%', null, null, '33.8125rem'],
  fontSize: '1.0625rem',
  mx: 'auto',
  mt: '1.5rem',
})``;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  mt: ['1.5rem', null, null, '3rem'],
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'space-between',
})`
  @media screen and (min-width: ${breakpoints[2]}) {
    .stat:last-of-type p {
      max-width: 18rem;
    }
  }
`;

export const ClientsSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Box backgroundColor="midnightBlue" color="cream">
      <Box maxWidth={props.containerMaxWidth} mx="auto" padding="1.5rem" pb="4.5rem" textAlign="center">
        <StyledH2>
          <span>{copy.clients.heading1}</span>

          <span className="highlight">{copy.clients.heading2}</span>

          <span>{copy.clients.heading3}</span>

          <span className="highlight">{copy.clients.heading4}</span>

          <span>{copy.clients.heading5}</span>
        </StyledH2>

        <StyledP2 mt="1.5rem" mx="auto">
          {copy.clients.description}
        </StyledP2>

        <MobileOnly>
          <StatsCarousel stats={copy.clients.stats} statColor="periwinkle" descriptionColor="cream" />
        </MobileOnly>

        <TabletUp>
          <StyledFlex>
            {copy.clients.stats.map((stat, i) => (
              <Stat key={i} statColor="periwinkle" descriptionColor="periwinkle" {...stat} />
            ))}
          </StyledFlex>
        </TabletUp>
      </Box>

      <LogoSection />
    </Box>
  );
};
