import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Video} from 'src/components/former-chief-marketing-web';
import {Testimonial} from 'src/components/homepage/shared/Testimonial';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {homepage as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({})`
  .testimonial span:nth-child(2) {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
  }

  .testimonial:nth-child(1) span:nth-child(2) {
    margin-right: 0.75rem;
  }

  .testimonial span:nth-child(1) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    .testimonial:nth-child(2) .inner-container {
      justify-content: flex-end;
    }

    .testimonial:nth-child(2) {
      margin-top: 9rem;
    }

    .testimonial:nth-child(1) span:nth-child(2) {
      margin-left: 0.75rem;
    }
  }

  .chief-video {
    padding: 0 !important; // Important needed to override framed padding in the component
  }
`;

export const TestimonialsSection: typeof FireworkContainer = ({...props}) => {
  return (
    <StyledBox backgroundColor="darkGreen" color="cream" padding="1.5rem" px={['1.5rem', null, null, 0]}>
      {copy.testimonials.map((testimonial, i) => (
        <Testimonial key={i} {...testimonial} />
      ))}

      <Box mt="4.5rem" maxWidth={props.containerMaxWidth} mx="auto">
        <Video
          url="https://www.youtube.com/embed/Ee7aUxx60Ic?si=sm8czYu94n80cX3D"
          controls="1"
          poster=""
          framed="0"
          muted="0"
          loop="0"
          className="chief-video"
        />
      </Box>
    </StyledBox>
  );
};
