import React, {useState, useEffect, useRef, forwardRef} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {motion, MotionProps, useAnimation} from 'framer-motion';
import {Button} from 'src/components/controls';
import {Stat} from 'src/components/homepage/shared/Stat';
import {Box, BoxProps, Flex} from 'src/components/shared';
import {Icon, Icons} from 'src/svgs';
import {swipePower, wrapIndex} from 'src/utils/slider';
import styled, {keyframes} from 'styled-components/macro';

export type StatsCarouselProps = {stats: any[]; statColor: string; descriptionColor: string; boxProps?: BoxProps};
type StatSlideProps = {stat: any; statColor: string; descriptionColor: string; active?: boolean};

const MotionContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const StatContainer = styled(motion.div).attrs({
  role: 'tabpanel',
  'aria-roledescription': 'slide',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
`;

const PageDot = styled.div<{active: boolean; statColor: string}>`
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid ${props => themeGet('colors.' + props.statColor)};
  border-radius: 62.5rem;
  background-color: ${props => (props.active ? themeGet('colors.' + props.statColor) : 'transparent')};
  margin: 0 0.25rem;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StatSlide = forwardRef<HTMLDivElement, StatSlideProps>(({stat, statColor, descriptionColor, active}, ref) => {
  return (
    <StatContainer ref={ref}>
      <Stat statColor={statColor} descriptionColor={descriptionColor} {...stat} />
    </StatContainer>
  );
});

const transition = {type: 'spring', mass: 0.25, bounce: 0, stiffness: 1000, damping: 50};
const dragTransition = {bounceDamping: transition.damping, bounceStiffness: transition.stiffness};

export const StatsCarousel: React.FC<StatsCarouselProps> = ({stats, statColor, descriptionColor, boxProps}) => {
  const [index, setIndex] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const animation = useAnimation();
  const indexRef = useRef(0);

  const handleNavigateLeft = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index - 1;
      await animation.start({
        x: parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index - 1);
  };

  const handleNavigateRight = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index + 1;
      await animation.start({
        x: -parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index + 1);
  };

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      console.log(event.key);
      if (event.key === 'ArrowRight') {
        handleNavigateRight();
      } else if (event.key === 'ArrowLeft') {
        handleNavigateLeft();
      }
    },
    [stats, index, setIndex],
  );

  const handleDragEnd: MotionProps['onDragEnd'] = async (evt, {offset}) => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const power = swipePower(offset.x, rect.width);
      if (power > 10) {
        await handleNavigateLeft();
      } else if (power < -10) {
        await handleNavigateRight();
      }
    }
  };

  useEffect(() => {
    parentRef.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      parentRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const prevIndex = wrapIndex(0, stats.length, index - 1);
  const currentIndex = wrapIndex(0, stats.length, index);
  const nextIndex = wrapIndex(0, stats.length, index + 1);

  return (
    <Box {...boxProps} aria-roledescription="carousel">
      <Box position="relative" height="100%" width="100%" overflow="hidden">
        <MotionContainer
          key={index}
          drag="x"
          layout
          dragConstraints={{left: 0, right: 0}}
          animate={animation}
          dragMomentum={false}
          dragDirectionLock
          onDragEnd={handleDragEnd}
          whileTap={{cursor: 'grabbing'}}
          dragTransition={dragTransition}
        >
          <StatSlide
            key={prevIndex}
            stat={stats[prevIndex]}
            statColor={statColor}
            descriptionColor={descriptionColor}
          />
          <StatSlide
            key={index}
            ref={parentRef}
            stat={stats[currentIndex]}
            statColor={statColor}
            descriptionColor={descriptionColor}
            active
          />
          <StatSlide
            key={nextIndex}
            stat={stats[nextIndex]}
            statColor={statColor}
            descriptionColor={descriptionColor}
          />
        </MotionContainer>

        <Flex position="absolute" bottom="0" width="100%" alignItems="center" justifyContent="center" zIndex={1}>
          {stats.map((stat, statIndex) => (
            <PageDot key={stat?._key} active={currentIndex === statIndex} statColor={statColor} />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

StatsCarousel.defaultProps = {
  statColor: 'neonGreen',
  descriptionColor: 'neonGreen',
  boxProps: {
    height: '16.625rem',
  },
};
