import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Box} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import styled from 'styled-components/macro';

const {H2} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  paddingX: [0, null, null, '1.5rem'],
  paddingY: ['1.5rem'],
  textAlign: 'center',
  borderRadius: '1.25rem',
  transition: 'background 1s',
})`
  transition: background 200ms;

  @media screen and (min-width: ${breakpoints[2]}) {
    &:hover {
      background: ${themeGet('colors.periwinkle')};
    }
  }
`;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: '1.1875rem',
  lineHeight: '1.1875rem',
  mb: '1.5rem',
})`
  font-family: ${fonts.heading};
  font-weight: 800;
  text-transform: uppercase;
`;

type AspectProps = {
  heading: string;
  icon: any;
  url: string;
};

export const Aspect: React.FC<AspectProps> = ({...props}) => {
  return (
    <StyledBox>
      <Anchor onClick={(e: MouseEvent) => FireworkCTAClick(e, 'homepage.invest', props.url)} to={props.url}>
        <StyledH2 color="midnightBlue">{props.heading}</StyledH2>

        {props.icon}
      </Anchor>
    </StyledBox>
  );
};
