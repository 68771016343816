import React from 'react';

import {Box, BoxProps} from 'src/components/shared';
import {useFillElementCount} from 'src/hooks/useFillElementCount';
import styled, {keyframes} from 'styled-components/macro';

export type LogosRowProps = BoxProps & {
  slideDirection?: 'left' | 'right';
  animationDuration?: string;
};

const Wrapper: typeof Box = styled(Box).attrs({
  overflow: 'hidden',
})``;

const slidingAnimation = keyframes`
  0% { 
    transform: translateX(-100%);  
  }
  100% { 
    transform: translateX(-50%);
  }
`;

const SlidingContainer = styled(Box)`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const IconGrid = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  left: '100%',
  zIndex: 1,
  height: '100%',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
})<{slideDirection: LogosRowProps['slideDirection']; animationDuration?: string}>`
  animation: ${slidingAnimation};
  animation-duration: ${props => props.animationDuration};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: ${props => (props.slideDirection === 'left' ? 'reverse' : 'normal')};
  transform-origin: right;
`;

const IconWrapper = styled(Box).attrs({
  className: 'icon-wrapper',
  height: '100%',
  mx: '1.25rem',
})`
  > svg {
    height: 100%;
    width: auto;
  }
`;

const renderChild = (child: React.ReactNode) => <IconWrapper>{child}</IconWrapper>;

export const LogosRow: React.FC<LogosRowProps> = ({children, slideDirection, animationDuration, ...props}) => {
  const {elementRef, wrapperRef, repeatCount} = useFillElementCount({minRepeatCount: 2});

  return (
    <Wrapper {...props} ref={wrapperRef}>
      <SlidingContainer>
        <IconGrid ref={elementRef} slideDirection={slideDirection} animationDuration={animationDuration}>
          {Array(repeatCount)
            .fill(children)
            .map(c => React.Children.map(c, renderChild))}
        </IconGrid>
      </SlidingContainer>
    </Wrapper>
  );
};

LogosRow.defaultProps = {
  height: '4.375rem',
  slideDirection: 'left',
  animationDuration: '60s',
};
