import React from 'react';

import {LogosRow} from 'src/components/homepage/shared/LogosRow';
import {Box, BoxProps, LayoutGrid, Section, Scrim} from 'src/components/shared';
import {useBreakpointValue} from 'src/hooks/useBreakpointValue';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import {DesktopUp, LaptopDown} from 'src/utils/responsive';
import styled from 'styled-components/macro';

import {SanityOrganizations} from 'graphql-types';

const Logo = styled(Icon).attrs({
  color: 'cream',
})``;

const StyledLogosRow: typeof LogosRow = styled(LogosRow).attrs({})`
  .icon-wrapper {
    margin-left: 3rem;
    margin-right: 3rem;
  }
`;

export const LogoSection: React.FC = ({...props}) => {
  const logoAnimationDuration = useBreakpointValue(['30s', null, null, '60s']) || '30s';

  return (
    <Box position="relative" py="4.5rem">
      <Scrim left="0" scrimColor="darkGreen" />
      <Scrim right="0" transform="rotate(180deg)" scrimColor="darkGreen" />

      <StyledLogosRow height="2rem" animationDuration={logoAnimationDuration}>
        <Logo aria-label={copy.logoSection.ariaLabels.bloomberg} icon={Icons.bloomberg} />
        <Logo aria-label={copy.logoSection.ariaLabels.wallStreetJournal} icon={Icons.wallStreetJournal} />
        <Logo aria-label={copy.logoSection.ariaLabels.forbes} icon={Icons.forbes} />
        <Logo aria-label={copy.logoSection.ariaLabels.inc500} icon={Icons.inc500} />
        <Logo aria-label={copy.logoSection.ariaLabels.todayShow} icon={Icons.todayShow} />
        <Logo aria-label={copy.logoSection.ariaLabels.time} icon={Icons.time} />
      </StyledLogosRow>
    </Box>
  );
};
