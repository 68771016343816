import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {Anchor, FireworkCTA} from 'src/components/controls';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {APPLY} from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {homepage as copy} from 'src/utils/copy';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

// NOTE: used to pad surrounding containers
export const NetworkingTopPositioning = [23.5, 25, 23.5, 16];

const StlyedFlex: typeof Flex = styled(Flex).attrs({
  width: '100%',
  px: '1.5rem',
  transform: 'translateY(-50%)',
})``;

const StyledBox: typeof Box = styled(Box).attrs({
  backgroundColor: 'neonGreen',
  borderRadius: '1.25rem',
  marginX: 'auto',
  padding: ['0.75rem', '1.5rem', '3rem', '4.5rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  color: 'darkGreen',
  width: ['100%', null, null, '65%'],
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})`
  span {
    font-style: italic;
    margin-left: 0.75rem;
  }
`;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'darkGreen',
  width: ['100%', null, null, 'auto'],
  mt: ['3rem', null, null, 0],
})``;

export const NetworkingSection: typeof FireworkContainer = ({...props}) => {
  return (
    <StlyedFlex>
      <Anchor mx="auto" onClick={(e: MouseEvent) => FireworkCTAClick(e, 'homepage.networking', APPLY)} to={APPLY}>
        <StyledBox maxWidth={props.containerMaxWidth}>
          <Flex
            width="100%"
            flexDirection={['column', null, null, 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <StyledH2 color="darkGreen">
              {copy.networking.heading1}
              <span>{copy.networking.heading2}</span>.
            </StyledH2>

            <Box width={['100%', null, null, 'auto']} minWidth="11.25rem">
              <StyledFireworkCTA to={APPLY}>{copy.networking.cta}</StyledFireworkCTA>
            </Box>
          </Flex>
        </StyledBox>
      </Anchor>
    </StlyedFlex>
  );
};
