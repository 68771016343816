import React from 'react';

import {LogosRow} from 'src/components/homepage/shared/LogosRow';
import {Box, BoxProps, LayoutGrid, Section, Scrim} from 'src/components/shared';
import {useBreakpointValue} from 'src/hooks/useBreakpointValue';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import {DesktopUp, LaptopDown} from 'src/utils/responsive';
import styled from 'styled-components/macro';

import {SanityOrganizations} from 'graphql-types';

const Logo = styled(Icon).attrs({
  color: 'cream',
})``;

export const LogoSection: React.FC = ({...props}) => {
  const logoAnimationDuration = useBreakpointValue(['60s', null, null, '120s']) || '60s';

  return (
    <Box position="relative" pb="4.5rem">
      <Scrim left="0" scrimColor="midnightBlue" />
      <Scrim right="0" transform="rotate(180deg)" scrimColor="midnightBlue" />

      <LogosRow animationDuration={logoAnimationDuration}>
        <Logo aria-label={copy.logoSection.ariaLabels.hulu} icon={Icons.hulu} />
        <Logo aria-label={copy.logoSection.ariaLabels.nbc} icon={Icons.nbc} />
        <Logo aria-label={copy.logoSection.ariaLabels.spotify} icon={Icons.spotify} />
        <Logo aria-label={copy.logoSection.ariaLabels.google} icon={Icons.google} />
        <Logo aria-label={copy.logoSection.ariaLabels.walmart} icon={Icons.walmart} />
        <Logo aria-label={copy.logoSection.ariaLabels.facebookRound} icon={Icons.facebookRound} />
        <Logo aria-label={copy.logoSection.ariaLabels.amazon} icon={Icons.amazon} />
        <Logo aria-label={copy.logoSection.ariaLabels.netflix} icon={Icons.netflix} />
        <Logo aria-label={copy.logoSection.ariaLabels.verizon} icon={Icons.verizon} />
        <Logo aria-label={copy.logoSection.ariaLabels.johnsonAndJohnson} icon={Icons.johnsonAndJohnson} />
        <Logo aria-label={copy.logoSection.ariaLabels.cartier} icon={Icons.cartier} />
        <Logo aria-label={copy.logoSection.ariaLabels.citi} icon={Icons.citi} />
      </LogosRow>

      <LogosRow mt="1.5rem" slideDirection="right" animationDuration={logoAnimationDuration}>
        <Logo aria-label={copy.logoSection.ariaLabels.goldmanSachs} icon={Icons.goldmanSachs} />
        <Logo aria-label={copy.logoSection.ariaLabels.newYorkTimes} icon={Icons.newYorkTimes} />
        <Logo aria-label={copy.logoSection.ariaLabels.allstate} icon={Icons.allstate} />
        <Logo aria-label={copy.logoSection.ariaLabels.pfizer} icon={Icons.pfizer} />
        <Logo aria-label={copy.logoSection.ariaLabels.chanel} icon={Icons.chanel} />
        <Logo aria-label={copy.logoSection.ariaLabels.hbo} icon={Icons.hbo} />
        <Logo aria-label={copy.logoSection.ariaLabels.disney} icon={Icons.disney} />
        <Logo aria-label={copy.logoSection.ariaLabels.nike} icon={Icons.nike} />
        <Logo aria-label={copy.logoSection.ariaLabels.jordans} icon={Icons.jordans} />
        <Logo aria-label={copy.logoSection.ariaLabels.mercedes} icon={Icons.mercedes} />
        <Logo aria-label={copy.logoSection.ariaLabels.audible} icon={Icons.audible} />
        <Logo aria-label={copy.logoSection.ariaLabels.ebay} icon={Icons.ebay} />
        <Logo aria-label={copy.logoSection.ariaLabels.universal} icon={Icons.universal} />
        <Logo aria-label={copy.logoSection.ariaLabels.square} icon={Icons.square} />
      </LogosRow>

      <LogosRow mt="1.5rem" animationDuration={logoAnimationDuration}>
        <Logo aria-label={copy.logoSection.ariaLabels.chobani} icon={Icons.chobani} />
        <Logo aria-label={copy.logoSection.ariaLabels.sonyMusic} icon={Icons.sonyMusic} />
        <Logo aria-label={copy.logoSection.ariaLabels.discovery} icon={Icons.discovery} />
        <Logo aria-label={copy.logoSection.ariaLabels.salesforce} icon={Icons.salesforce} />
        <Logo aria-label={copy.logoSection.ariaLabels.doordash} icon={Icons.doordash} />
        <Logo aria-label={copy.logoSection.ariaLabels.uber} icon={Icons.uber} />
        <Logo aria-label={copy.logoSection.ariaLabels.squarespace} icon={Icons.squarespace} />
        <Logo aria-label={copy.logoSection.ariaLabels.toyota} icon={Icons.toyota} />
        <Logo aria-label={copy.logoSection.ariaLabels.sony} icon={Icons.sony} />
        <Logo aria-label={copy.logoSection.ariaLabels.ibm} icon={Icons.ibm} />
        <Logo aria-label={copy.logoSection.ariaLabels.headspace} icon={Icons.headspace} />
        <Logo aria-label={copy.logoSection.ariaLabels.samsung} icon={Icons.samsung} />
      </LogosRow>
    </Box>
  );
};
