import React from 'react';

import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Flex, Image} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import styled from 'styled-components/macro';

const {Article} = Typography;

const StlyedAnchor: typeof Anchor = styled(Anchor).attrs({
  width: ['100%', null, null, 'calc(33% - 0.75rem)'],
})``;

type ArticleProps = {
  pictureUrl: string;
  title: string;
  articleUrl: string;
};

const handleClickLink = (clickText: string, clickUrl: string) => {
  Events.didClickLink({clickText, clickUrl});
};

export const ArticleItem: React.FC<ArticleProps> = ({...props}) => {
  return (
    <StlyedAnchor
      mb={['1.5rem', null, null, 0]}
      onClick={() => handleClickLink('homepage.articles.item', props.articleUrl)}
      to={props.articleUrl}
    >
      <Flex flexDirection={['row', null, null, 'column']}>
        <Image width={['32%', null, null, '100%']} src={props.pictureUrl} aria-label={props.title} />

        <Article mt={[0, null, null, '1.5rem']} pl={['0.75rem', null, null, 0]}>
          {props.title}
        </Article>
      </Flex>
    </StlyedAnchor>
  );
};
