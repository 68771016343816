import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  className: 'testimonial',
  width: '100%',
  mt: '3rem',
  textAlign: ['center', null, null, 'left'],
})``;

const InnerContainer: typeof Flex = styled(Flex).attrs({
  className: 'inner-container',
  width: 'auto',
  position: 'relative',
  flexDirection: ['column', null, null, 'row'],
})`
  &:after {
    content: ' ';
    background-color: ${themeGet('colors.neonGreen')};
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    display: none;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    &:after {
      display: block;
    }
  }
`;

const TextContainer: typeof Box = styled(Box).attrs({
  className: 'text-container',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  backgroundColor: 'darkGreen',
  width: 'auto',
  maxWidth: '62.5rem',
  fontSize: ['2.875rem', null, null, '3.6875rem'],
  lineHeight: ['3rem', null, null, '3.6875rem'],
  position: 'relative',
  px: [null, null, null, '3rem'],
  zIndex: 100,
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  maxWidth: ['22rem', null, null, 'none'],
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  mt: ['0.75rem'],
  mx: ['auto', null, null, 0],
  px: [null, null, null, '3rem'],
})``;

type TestimonialProps = {
  quoteStrings: Array<string>;
  attribution: string;
};

export const Testimonial: React.FC<TestimonialProps> = ({...props}) => {
  return (
    <StyledBox>
      <InnerContainer>
        <TextContainer>
          <StyledH2>
            {props.quoteStrings.map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </StyledH2>

          <StyledP2>— {props.attribution}</StyledP2>
        </TextContainer>
      </InnerContainer>
    </StyledBox>
  );
};
